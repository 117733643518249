@charset "UTF-8";
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html, body {
  min-height: 100vh;
}

html {
  font-size: 62.5%;
}

img {
  max-width: 100%;
  height: auto;
  line-height: normal;
}

body {
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 150rem;
  margin: 0 auto;
}

.fal {
  font-weight: 400;
}

::selection {
  background-color: #333;
  color: #fff;
}

body.cms-no-route .columns {
  max-width: 144rem;
  margin: 0 auto;
  margin-bottom: 13rem;
  padding-top: 4.5rem;
}

body.cms-no-route .columns a {
  font-weight: bold;
}

.column-main {
  position: relative;
}

@font-face {
  font-family: 'Minion Pro';
  src: url("../../fonts/MinionPro-Regular.eot");
  src: url("../../fonts/MinionPro-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/MinionPro-Regular.woff2") format("woff2"), url("../../fonts/MinionPro-Regular.woff") format("woff"), url("../../fonts/MinionPro-Regular.ttf") format("truetype"), url("../../fonts/MinionPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url("../../fonts/MinionPro-Bold.eot");
  src: url("../../fonts/MinionPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/MinionPro-Bold.woff2") format("woff2"), url("../../fonts/MinionPro-Bold.woff") format("woff"), url("../../fonts/MinionPro-Bold.ttf") format("truetype"), url("../../fonts/MinionPro-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/Roboto-Medium.eot");
  src: url("../../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/Roboto-Medium.woff2") format("woff2"), url("../../fonts/Roboto-Medium.woff") format("woff"), url("../../fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/Roboto-Bold.eot");
  src: url("../../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/Roboto-Bold.woff2") format("woff2"), url("../../fonts/Roboto-Bold.woff") format("woff"), url("../../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/Roboto-Light.eot");
  src: url("../../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/Roboto-Light.woff2") format("woff2"), url("../../fonts/Roboto-Light.woff") format("woff"), url("../../fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url("../../fonts/Roboto-Regular.eot");
  src: url("../../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Roboto-Regular.woff2") format("woff2"), url("../../fonts/Roboto-Regular.woff") format("woff"), url("../../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*=============================================
=            Price Fonts            =
=============================================*/
body {
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: normal;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Minion Pro';
  font-weight: normal;
  text-align: left;
  text-align: left;
  color: #333;
}

h1 {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 300;
  margin: 0 0 2rem 0;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
    line-height: 1.2;
    margin: 0 0 2rem 0;
  }
}

h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
}

h2:first-of-type {
  padding-top: 0;
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 3rem;
    line-height: 1.2;
    margin: 0 0 2rem 0;
  }
}

h3 {
  font-size: 2rem;
  line-height: 2rem;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 2.0rem;
  font-family: 'Minion Pro';
  font-weight: normal;
  text-align: left;
  padding-bottom: 2.8rem;
}

@media only screen and (max-width: 480px) {
  h4 {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 420px) {
  h4 {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
}

@media only screen and (max-width: 480px) {
  h4 {
    padding-bottom: 0.9rem;
  }
}

a {
  color: #333;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

ol,
ul {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}

p {
  font-family: 'Roboto';
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: normal;
  color: #333;
  margin-bottom: .8rem;
  color: #333;
}

small, .small {
  font-size: 1.2rem;
}

@media only screen and (max-width: 1530px) {
  header .header-main-logo {
    padding-bottom: 0;
  }
  header .header-main-navigation {
    margin-left: -1rem;
  }
  header .header-main-navigation ul li a {
    padding: 2rem 1rem;
    font-size: 1.6rem;
  }
  /*.login{
        &-container{
            @include flexWrap;
            .block{
                width: 100%;
            }
        }
    }*/
  .account .table-wrapper table thead {
    display: none;
  }
  .account .table-wrapper table tbody {
    border-bottom: 0;
  }
  .account .table-wrapper table tr {
    padding-top: 2rem;
  }
  .account .table-wrapper table td.col {
    padding-left: 0;
    padding-bottom: 1rem;
  }
  .account .table-wrapper table td.col[data-th]::before {
    padding-bottom: 0;
  }
  .account .column .block-content .box {
    width: 49%;
  }
  .account .column .block-content .box:nth-of-type(3n) {
    margin-right: 2%;
  }
  .account .column .block-content .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .cart-summary #block-discount {
    margin-left: 0;
    width: 100%;
  }
  .cart-container form {
    width: 100%;
  }
  #checkout-shipping-method-load {
    padding: 1rem 2rem;
  }
  .step-content table.table-checkout-shipping-method tr {
    flex-wrap: nowrap;
  }
  .step-content table.table-checkout-shipping-method td.col {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0;
  }
  .step-content table.table-checkout-shipping-method td.col > span {
    padding-right: .5rem;
  }
  .step-content table.table-checkout-shipping-method td.col > * {
    margin-top: 0;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(3) h2 {
    padding-bottom: 0;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(4), .webcrossing-widget-category-boxlist ul li:nth-of-type(5) {
    width: 39.6%;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(6) {
    width: 20.8%;
  }
  .webcrossing-widget-category-boxlist h2 {
    line-height: normal;
  }
}

@media only screen and (max-width: 1200px) {
  header .header-main-search {
    margin-left: 0;
  }
  header .header-main-search, header .header-main-account {
    margin-right: 1.5rem;
  }
  header .header-main-logo .logo img {
    display: none;
  }
  .page-wrapper .breadcrumbs {
    /*padding-top: 1.4rem;
                padding-bottom: 1.4rem;*/
  }
  .splide__arrow.splide__arrow--prev, .splide__arrow.splide__arrow--next {
    /*width: .8rem;
                        height: 3.1rem;*/
  }
  .splide__arrow.splide__arrow--prev {
    /*left: 1rem;*/
    /*&:before{
                            background-image: url($imageDirResp + "arrow-left.png");
                        }*/
  }
  .splide__arrow.splide__arrow--next {
    /*right: 1rem;*/
    /* &:before{
                            background-image: url($imageDirResp + "arrow-right.png");
                        }*/
  }
  .cms-page-view .header-main, .catalog-product-view .header-main {
    box-shadow: 0px 4px 5px 0px rgba(72, 76, 82, 0.05);
  }
  .cms-page-view .header-main-container, .catalog-product-view .header-main-container {
    box-shadow: none;
  }
  .catalog-product-view .product-info-media {
    max-width: 50%;
  }
  .catalog-product-view .product-info-main {
    width: 50%;
  }
  .webcrossing-widget-category-boxlist .overlay {
    padding-right: 2rem;
  }
  .webcrossing-widget-category-boxlist ul li {
    padding-right: 2rem;
    margin-bottom: 2rem;
  }
  .webcrossing-widget-category-boxlist ul li:first-child {
    width: 42%;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(3) {
    width: 30%;
  }
  .webcrossing-widget-teaser-box {
    margin-top: 8rem;
  }
  .webcrossing-widget-teaser-box .block-content .right {
    padding: 0 2rem;
    padding-left: 7rem;
    max-width: 49.2%;
  }
  .webcrossing-widget-image-slider .block-content .splide__arrows {
    display: none;
  }
  .page-title h2 {
    line-height: 5rem;
  }
  .cms-home .page-title {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  footer .footer-boxes .inner-container {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media only screen and (max-width: 1023px) {
  .checkout-index-index header .header-main {
    display: none;
  }
  header .header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  header .header.content .nav-toggle {
    order: 1;
    display: inline-block;
    border: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    width: 2.269rem;
    line-height: 0;
  }
  header .header.content .nav-toggle:before, header .header.content .nav-toggle:after, header .header.content .nav-toggle span {
    height: .2rem;
    display: block;
    background: #333;
  }
  header .header.content .nav-toggle:before, header .header.content .nav-toggle:after {
    content: "";
    width: 100%;
  }
  header .header.content .nav-toggle span {
    font-size: 0;
    width: 100%;
    margin-top: .5rem;
  }
  header .header.content .nav-toggle:after {
    margin-top: .5rem;
  }
  header .header.content .block-search {
    order: 2;
    width: 25rem;
  }
  header .header.content .logo {
    order: 0;
  }
  header .header.content .minicart-wrapper {
    order: 3;
    width: auto;
  }
  .navigation.mm-menu ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .navigation.mm-menu ul li {
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row-reverse;
    line-height: normal;
  }
  .navigation.mm-menu ul li.level0 > .level-top {
    line-height: normal;
    width: 100%;
  }
  .navigation.mm-menu ul li a {
    padding: 1.5rem 2rem 1.5rem 1.2rem;
    line-height: normal;
    width: 100%;
  }
  .mm-panel {
    padding: 0;
  }
  .mm-panels > .mm-panel .mm-listview {
    margin: 2rem 0 !important;
  }
  .mm-navbar {
    display: block;
  }
  .mm-listitem:after {
    left: 0;
  }
  .mm-slideout.mm-page {
    background: #fff;
  }
  .nav {
    /*
        &-open{
            .page-wrapper{
                left: 70%;
            }
            .nav-sections{
                left: 0;
            }

        }*/
  }
  .page-wrapper {
    left: 0;
    position: relative;
    transition: all ease .2s;
  }
  .sidebar-main {
    display: none;
  }
  .account .sidebar-main {
    display: block;
  }
  .webcrossing-widget-image-slider .block-content .splide__arrows {
    display: none;
  }
  .webcrossing-widget-image-slider .block-content .splide__pagination {
    z-index: 5;
    display: inline-block;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1530px) {
  .webcrossing-widget-image-slider .block-content .splide__pagination {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1024px) {
  .webcrossing-widget-image-slider .block-content .splide__pagination {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) {
  .webcrossing-widget-image-slider .block-content ul li button {
    cursor: pointer;
  }
  .webcrossing-widget-image-slider .block-content ul li button.is-active {
    background: #fff;
    opacity: 0.8;
  }
  .webcrossing-widget-image-slider .block-content ul li .image {
    z-index: 0;
  }
  .webcrossing-widget-image-slider .block-content ul li .info {
    width: 100%;
    z-index: 2;
    bottom: 4rem;
    top: inherit;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1530px) {
  .webcrossing-widget-image-slider .block-content ul li .info {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1024px) {
  .webcrossing-widget-image-slider .block-content ul li .info {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) {
  .webcrossing-widget-image-slider .block-content ul li .info .actions a {
    margin-right: 1rem;
  }
  .webcrossing-widget-image-slider .block-content ul li .info.right, .webcrossing-widget-image-slider .block-content ul li .info.left {
    right: inherit;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1530px) {
  .webcrossing-widget-category-boxlist .block-title {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1024px) {
  .webcrossing-widget-category-boxlist .block-title {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) {
  .webcrossing-widget-category-boxlist .block-content {
    max-width: 100%;
    padding: 0;
  }
  .webcrossing-widget-category-boxlist ul li {
    padding-right: 0;
    margin-right: 2%;
  }
  .webcrossing-widget-category-boxlist ul li .overlay {
    padding-right: 0;
  }
  .webcrossing-widget-category-boxlist ul li:hover .image img {
    transform: scale(1) translate(-50%, -50%);
  }
  .webcrossing-widget-category-boxlist ul li:first-child {
    width: 49%;
    padding-right: 0;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(3n), .webcrossing-widget-category-boxlist ul li:nth-of-type(4), .webcrossing-widget-category-boxlist ul li:nth-of-type(5) {
    width: 49%;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(2n) {
    width: 49%;
    margin-right: 0;
  }
  .webcrossing-widget-category-boxlist .overlay {
    bottom: 0;
  }
  .webcrossing-widget-teaser-box .block-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .webcrossing-widget-teaser-box .block-content .left {
    width: 48%;
    min-width: inherit;
    margin-bottom: 4rem;
  }
  .webcrossing-widget-teaser-box .block-content .info {
    width: 50%;
    margin-right: 0;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 3rem;
  }
  .webcrossing-widget-teaser-box .block-content .info .description {
    padding-bottom: 4rem;
  }
  .webcrossing-widget-teaser-box .block-content .right {
    width: 100%;
    position: relative;
    max-width: 100%;
    padding-left: 0;
    text-align: center;
    padding-right: 0;
  }
  .webcrossing-widget-teaser-box .box {
    width: 100%;
    padding: 10rem 0;
  }
  .webcrossing-widget-teaser-box .box.right .info .actions {
    padding-top: 3rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1530px) {
  .webcrossing-widget-teaser-box .info {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) and (max-width: 1024px) {
  .webcrossing-widget-teaser-box .info {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 1023px) {
  .webcrossing-widget-teaser-box .info .description {
    padding-bottom: 0;
    font-size: 1.5rem;
    font-weight: 300;
  }
  .details-wrapper {
    justify-content: space-between;
  }
  .shipping-information {
    margin-top: 0;
  }
  .opc-progress-bar, .opc-wrapper, .opc-sidebar {
    width: 100%;
    max-width: 100%;
  }
  .opc-sidebar {
    /*margin-bottom: 6rem;*/
    margin-bottom: 0;
    margin-top: 3rem;
  }
  .opc-sidebar .modal-inner-wrap {
    max-width: 100%;
  }
  .opc-block-summary, .opc-block-shipping-information {
    max-width: calc((100% - 1.5rem) / 2);
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .opc-block-summary {
    margin-right: 1.5rem;
  }
  .opc-progress-bar {
    padding-top: 0;
  }
  .catalog-product-view .product-info-description {
    padding: 0 0 5rem;
  }
  .catalog-product-view .product-add-form {
    padding-right: 0;
  }
  .login-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5rem;
  }
  .login-container .block {
    width: 100%;
  }
  .cart-summary {
    width: 100%;
    margin-top: 7rem;
  }
  .cart-container {
    flex-direction: column-reverse;
  }
  .cart-container form.form-cart {
    width: 100%;
    order: 0;
  }
  .cart-membership {
    width: 49%;
    margin-top: 0rem;
  }
  .cart-membership {
    margin-left: 2%;
  }
  .cart-summary {
    order: 0;
  }
  .cart-summary .summary.title {
    padding-bottom: 3rem;
  }
  footer .footer-main .section {
    width: 100%;
  }
  footer .footer-main .section01 {
    padding-bottom: 2rem;
  }
  footer .footer-main-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  footer .footer-main-container ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  footer .footer-main-container form {
    justify-content: center;
  }
  footer .footer-bottom {
    justify-content: center;
  }
  #checkout-shipping-method-load table tbody tr {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .column table tr td.col.item {
    width: auto;
  }
  .column table tr td.col.action {
    top: 4rem;
  }
  .page-layout-storytelling-layout .category-page-header-text::after {
    display: none;
  }
  .page-layout-storytelling-layout .webcrossing-widget-category-checklist .description {
    column-count: 2;
  }
  .page-layout-storytelling-layout .products.list .product-item {
    width: 32%;
  }
  .page-layout-storytelling-layout .products.list .product-item:nth-of-type(4n) {
    margin-right: 1%;
  }
  .page-layout-storytelling-layout .products.list .product-item:nth-of-type(5n) {
    margin-right: 1%;
  }
  .page-layout-storytelling-layout .products.list .product-item:nth-of-type(3n) {
    margin-right: 0;
  }
  .page-layout-2columns-left .columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .page-layout-2columns-left .columns .column {
    width: 100%;
  }
  .account .sidebar-main, .page-products .sidebar-main {
    width: 100%;
  }
  .account .choice {
    align-items: flex-start;
  }
  .account .choice input {
    margin-top: .7rem;
  }
  .account .actions-toolbar .primary {
    margin-right: 1rem;
  }
  .category-page-header .page-title-wrapper {
    vertical-align: center;
  }
  .category-page-header .page-title-wrapper h1 {
    line-height: normal;
  }
  .category-page-header-text {
    /*left: 25%;
            transform: translate(-14%, 0);*/
  }
  .banner-headline-wrapper {
    padding: 5rem 7rem 1rem;
    width: 100%;
    max-width: 50rem;
    background: transparent;
    overflow: hidden;
    /*left: 25%;
        transform: translate(-14%, 0);*/
  }
  .banner-headline-wrapper:before {
    position: absolute;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    bottom: 1px;
    left: 0;
    width: 100%;
    content: "";
    align-items: normal;
  }
  .filter {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  header .header-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    /*height: 169px;*/
  }
  header .header-navigation ul li a {
    padding: 3.5rem 1rem;
  }
  header .header-navigation ul .level0 {
    padding-right: 0;
  }
  header .header-navigation.desktop {
    display: none;
  }
  header .header-navigation.mobile {
    display: inline-block;
    padding: 0 2rem;
  }
  header .header-navigation.mobile button {
    display: inline-block;
    border: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    width: 2.269rem;
    line-height: 0;
  }
  header .header-navigation.mobile button:before, header .header-navigation.mobile button:after, header .header-navigation.mobile button span {
    height: .2rem;
    display: block;
    background: #333;
  }
  header .header-navigation.mobile button:before, header .header-navigation.mobile button:after {
    content: "";
    width: 100%;
  }
  header .header-navigation.mobile button span {
    width: 100%;
    margin-top: .5rem;
  }
  header .header-navigation.mobile button:after {
    margin-top: .5rem;
  }
  header .header-search .block-content {
    position: absolute;
    top: 100%;
    width: 100%;
    right: 0;
    max-width: 100%;
    max-height: 0;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
    opacity: 0;
  }
  header .header-search .block-content form {
    padding: 1rem 2rem;
    margin-right: 0;
  }
  header .header-search .active .block-content {
    opacity: 1;
    max-height: 8rem;
  }
  header .header-account a, header .header-account .block-title, header .header-search a, header .header-search .block-title, header .header-cart a, header .header-cart .block-title {
    /*&:after, &:before{
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                    }*/
  }
  .block-title h2 {
    font-size: 2.8rem;
  }
  .block-title h2 span {
    width: auto;
    display: inline-block;
  }
  .toolbar-products {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
  }
  .toolbar .limiter label, .toolbar .sorter label {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
  }
  .toolbar .limiter .control, .toolbar .sorter .control {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .toolbar .limiter select, .toolbar .sorter select {
    width: 100%;
    height: 4.3rem;
    font-size: 1.5rem;
  }
  .toolbar .sorter {
    margin-left: 0;
  }
  .toolbar .limiter {
    order: 1;
  }
  .webcrossing-widget-image-slider .block-content ul li .image img {
    max-height: 100%;
  }
  .webcrossing-widget-image-slider .block-content ul li .info h2 {
    font-weight: 300;
  }
  .webcrossing-widget-image-slider .block-content ul li .info .actions a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-align: center;
  }
  .webcrossing-widget-image-slider .block-content ul li .info .actions a:last-child {
    margin-right: 0;
  }
  .webcrossing-widget-image-slider .block-content ul li .info h2, .webcrossing-widget-image-slider .block-content ul li .info h3 {
    font-size: 4.5rem;
    margin-top: 0;
    line-height: 4rem;
  }
  .webcrossing-widget-textimage-triple .textimage {
    margin-right: 0;
  }
  .webcrossing-widget-textimage-triple .block-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .banner-headline-wrapper:before {
    align-items: normal;
  }
  .catalog-product-view .related .products.list .product-item {
    width: calc((100% - 3rem) / 3);
  }
  .catalog-product-view .related .products.list .product-item:nth-of-type(2n), .catalog-product-view .related .products.list .product-item:nth-of-type(4n) {
    margin-right: 1.5rem;
  }
  .catalog-product-view .related .products.list .product-item:nth-of-type(3n) {
    margin-right: 0;
  }
  .catalog-product-view .product.media {
    width: 100%;
  }
  .catalog-product-view .product-info-main {
    width: 100%;
  }
  .catalog-product-view .product-info-main {
    padding-left: 0;
    padding-top: 3rem;
  }
  .catalog-product-view .product-info-detail .details ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .catalog-product-view .product-info-detail .details ul li {
    width: 100%;
    margin-bottom: 3rem;
  }
  .catalog-product-view .product.detailed .items .item.title {
    width: 100%;
    padding: 0;
    padding-top: 2rem;
  }
  .catalog-product-view .product.detailed .items .item.title a {
    text-align: left;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
  }
  .catalog-product-view .product.detailed .items .item.title a:after {
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
  }
  .catalog-product-view .product.detailed .items .item.title.active a:after {
    content: "";
  }
  .catalog-product-view .product.detailed .items .item.content {
    margin-left: 0;
    margin-top: 0;
  }
  .page-layout-2columns-left .page-wrapper .columns {
    width: 100%;
  }
  .opc-block-summary, .opc-block-shipping-information {
    max-width: 100%;
  }
  .opc-block-summary {
    margin-right: 0;
  }
  .shipping-address-item {
    width: 49%;
  }
  .shipping-address-item:nth-of-type(3n) {
    margin-right: 2%;
  }
  .shipping-address-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .shipping-information {
    margin-top: 4rem;
  }
  .column table {
    display: flex;
    flex-wrap: wrap;
  }
  .column table thead {
    width: 100%;
  }
  .column table tbody.cart.item {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .column table tr {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .column table tr.item-info {
    justify-content: space-between;
  }
  .column table tr th {
    display: none;
  }
  .column table tr td {
    /*@include flex;
                    flex-wrap: wrap;*/
    text-align: left;
  }
  .column table tr td.amount {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .column table tr td.amount[data-th]:before {
    content: attr(data-th) ":";
    width: 100%;
    padding: 0 2rem 1rem 0rem;
    padding-right: 0;
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
  .column table tr td.amount span {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .column table tr td.amount span.price {
    justify-content: flex-end;
  }
  .column table tr td.amount span[data-th]:before {
    content: attr(data-th) ":";
    width: 100%;
    padding: 0 2rem 1rem 0rem;
    padding-right: 0;
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
  .column table tr td.col[data-th]:before {
    content: attr(data-th) ":";
    width: 100%;
    padding: 0 2rem 1rem 0rem;
    padding-right: 0;
    text-align: left;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
  .column table tr td.col {
    width: auto;
    text-align: left;
    padding-top: 0;
    padding-bottom: 3rem;
    padding-right: 1rem;
    position: inherit;
  }
  .column table tr td.col > * {
    margin-top: 1rem;
    display: block;
  }
  .column table tr td.col .actions-toolbar {
    position: absolute;
    padding: 0;
    top: 4rem;
    right: 0;
    margin-right: 0;
    margin-top: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column table tr td.col .actions-toolbar a {
    display: inline-block;
  }
  .column table tr td.col.subtotal {
    padding-right: 0;
  }
  .column table tr td.col.price {
    padding-left: 16rem;
  }
  .column table tr td.col.item {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
  }
  .column table tr td.col.item > a {
    width: auto;
    display: inline-block;
  }
  .column table tr td.col.item .product-item-details {
    display: inline-block;
    vertical-align: top;
  }
  .column table tr td.col.qty {
    padding-top: 0;
  }
  .cart-sidebar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .cart-membership, .cart-summary {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .cart-membership {
    margin-top: 5rem;
  }
  .cart-container form .actions {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
  }
  .cart-container form .actions > .action {
    margin-left: 0;
    margin-bottom: 1rem;
    line-height: normal;
    width: 100%;
  }
  .account .block-content .box {
    width: 49%;
  }
  .account .block-content .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .account .block-content .table-wrapper {
    width: 100%;
  }
  .account .block-content table {
    width: 100%;
  }
  .account .block-content table tr {
    display: block;
    padding-bottom: 2rem;
  }
  .account .block-content table tr th {
    display: none;
  }
  .account .block-content table tr td {
    display: block;
    padding: 5px 10px;
  }
  .account .block-content table tr td.col[data-th]::before {
    width: auto;
    display: inline-block;
    margin-right: 1rem;
  }
  .account .block-content table tr td.actions {
    justify-content: flex-start;
  }
  .account .block-content table tr td.actions a {
    font-weight: 500;
  }
  .account .block-content table tr td.actions a:before {
    display: none;
  }
  .account .block-content table tr td.actions a.edit {
    margin-left: 1rem;
    color: #333;
  }
  .account .block-content table tr td.actions a.delete {
    color: #fa1b1b;
  }
  .account .block-content table tr td.actions a span {
    width: auto;
    height: auto;
    display: inline-block;
    position: relative;
    overflow: initial;
  }
  .account .block-content table tr td:before {
    font-weight: bold;
    content: attr(data-th) ": ";
  }
  .account .table-wrapper table tr {
    padding-top: 0;
  }
  .account .table-wrapper table tr td.col {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .account .table-wrapper table tr td.col[data-th]::before {
    width: auto;
  }
  .account .table-wrapper table tr td.col .price {
    margin-top: 0;
  }
  .account .table-wrapper table tr td.col a {
    margin-top: 0;
  }
  footer .footer-content {
    margin-top: 5rem;
  }
  footer .footer-main {
    text-align: center;
    justify-content: center;
  }
  footer .footer-main h2 {
    text-align: center;
  }
  footer .footer-main .section {
    width: 100%;
  }
  footer .footer-main-info {
    padding-left: 0;
  }
  footer .footer-main-links {
    margin-left: 0;
    padding-left: 0;
  }
  footer .footer-main nav > ul > li {
    width: 100%;
  }
  footer .footer-bottom {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-bottom .section {
    text-align: center;
  }
  footer .footer-bottom .section a {
    margin: 0 auto;
  }
  footer .footer-bottom-socialmedia {
    margin-top: 1rem;
    width: 100%;
  }
  footer .footer-bottom ul {
    justify-content: center;
  }
  .limiter-text {
    white-space: nowrap;
  }
  .products.list .product-item {
    width: calc((100% - 1.5rem) / 2);
  }
  .products.list .product-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .products.list .product-item-overlay {
    position: relative;
    bottom: inherit;
    left: inherit;
    height: auto;
    opacity: 1;
    margin-top: 2rem;
  }
  .products.list .product-item-overlay .actions {
    width: 100%;
  }
  .checkout-agreement {
    flex-wrap: nowrap;
  }
  .checkout-agreement label {
    font-size: 1.3rem;
  }
  .checkout-agreement input {
    width: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  header .header-main.sticky .inner-main-container.right {
    padding-right: 3rem;
    width: auto;
  }
  header .header-search, header .header-account {
    margin-right: 1.5rem;
  }
  header .header.content .block-search {
    padding: 0 2rem;
  }
  .webcrossing-widget-teaser-box .block-content .left {
    width: 100%;
    margin-bottom: 4rem;
  }
  .webcrossing-widget-teaser-box .block-content .info {
    width: 100%;
  }
  .webcrossing-widget-teaser-image .block-content .overlay {
    padding: 1.5rem 0;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    background: #F9F7F1;
    top: inherit;
  }
  .webcrossing-widget-teaser-image .block-content .overlay .arrow-down {
    bottom: inherit;
    margin-top: 1rem;
    position: relative;
  }
  .webcrossing-widget-teaser-text {
    padding-top: 4rem;
  }
  .webcrossing-widget-product-sliderwithbanner .block-content .actions a {
    width: auto;
  }
  .webcrossing-widget-category-boxlist ul li .overlay {
    padding: 0 2rem;
  }
  .webcrossing-widget-category-boxlist ul li:first-child {
    width: 100%;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(2n), .webcrossing-widget-category-boxlist ul li:nth-of-type(3n), .webcrossing-widget-category-boxlist ul li:nth-of-type(5) {
    width: 100%;
    margin-right: 0;
  }
  .webcrossing-widget-category-boxlist ul li:nth-of-type(3n) .overlay {
    padding-right: 2rem;
  }
  .account .actions .action {
    width: auto;
    display: inline-block;
  }
  .account .actions-toolbar {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .account .actions-toolbar .primary {
    margin-bottom: 1rem;
  }
  .account .actions-toolbar .primary, .account .actions-toolbar .secondary {
    width: 100%;
  }
  .account .actions-toolbar .primary a, .account .actions-toolbar .secondary a {
    width: 100%;
  }
  .account .column .block-content .box {
    width: 100%;
    margin-right: 0;
  }
  .account .column .block-content .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .catalog-product-view .related {
    width: 100%;
  }
  .catalog-product-view .related .products.list .product-item {
    width: calc((100% - 1.5rem) / 2);
  }
  .catalog-product-view .related .products.list .product-item:nth-of-type(3n), .catalog-product-view .related .products.list .product-item:nth-of-type(4n) {
    margin-right: 1.5rem;
  }
  .catalog-product-view .related .products.list .product-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .catalog-product-view .product-info-main {
    padding-bottom: 0;
  }
  .catalog-product-view .product-info-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .catalog-product-view .product-add-form {
    width: 100%;
  }
  .catalog-product-view .product-add-form .box-tocart .fieldset .field.qty {
    margin-top: 0;
    margin-right: 2rem;
  }
  .catalog-product-view .product-add-form .box-tocart .fieldset .actions {
    width: auto;
    margin-left: 0;
  }
  .catalog-product-view .product-add-form .box-tocart .fieldset .actions button {
    width: auto;
  }
  #shipping-new-address-form > .field {
    width: 100%;
  }
  .shipping-address-item {
    width: 100%;
    margin-right: 0;
  }
  .shipping-address-item:nth-of-type(3n) {
    margin-right: 0;
  }
  .shipping-address-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .opc-block-shipping-information .action {
    width: auto;
  }
  .nav-open .page-wrapper {
    left: calc(100% - 6rem);
  }
  .page-layout-storytelling-layout .webcrossing-widget-category-checklist .description {
    column-count: 1;
  }
  .page-layout-storytelling-layout .products.list .product-item {
    width: 49%;
    margin-right: 2%;
  }
  .page-layout-storytelling-layout .products.list .product-item:nth-of-type(3n) {
    margin-right: 2%;
  }
  .page-layout-storytelling-layout .products.list .product-item:nth-of-type(2n) {
    margin-right: 0;
  }
  .page-layout-1column:not(.cms-home) .column {
    margin-top: 4rem;
  }
  .page-main .actions-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .column table tr.item-actions td .actions-toolbar {
    bottom: inherit;
    top: 1rem;
    flex-wrap: nowrap;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column table tr.item-actions td .actions-toolbar .action {
    padding: 0;
  }
  .column table tr.item-actions td .actions-toolbar .action + .action {
    margin-top: 0;
    padding-left: 1rem;
  }
  .column table tr td.col.item .product-item-details {
    padding-right: 3rem;
    padding-left: 0;
    width: calc(100% - 15rem);
  }
  .column table tr td.col.price {
    padding-left: 1rem;
  }
  .splide__arrows {
    position: relative;
    width: 100%;
    top: inherit;
    right: inherit;
    padding-bottom: 2rem;
  }
  .products.list .product-item {
    margin-right: 1.5rem;
  }
  .products.list .product-item:nth-of-type(3n) {
    margin-right: 1.5rem;
  }
  .products.list .product-item:nth-of-type(2n) {
    margin-right: 0;
  }
  footer .footer-boxes-block {
    width: 100%;
  }
  footer .footer-bottom ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-main-container ul li {
    width: 100%;
  }
  footer .footer-main-container ul li a {
    padding-bottom: 1rem;
    display: block;
    margin-right: 0;
  }
  footer .footer-main-container form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  footer .footer-main-container form .actions, footer .footer-main-container form .field {
    width: 100%;
  }
  footer .footer-main-container form .action {
    width: 100%;
    margin-top: 2rem;
    margin-left: 0;
  }
  .block-title h2 {
    width: 100%;
  }
  .toolbar .limiter label, .toolbar .limiter .limiter-text, .toolbar .sorter label, .toolbar .sorter .limiter-text {
    display: none;
  }
  .toolbar .limiter select, .toolbar .sorter select {
    width: auto;
  }
}

@media only screen and (max-width: 360px) {
  .category-page-header {
    padding-top: calc(248.44 / 400.34 * 100%);
  }
  .category-page-header-text {
    padding: 2rem 1rem 1rem;
  }
  .category-page-header-text:after {
    height: 100%;
  }
}
