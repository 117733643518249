@font-face {
    font-family: 'Minion Pro';
    src: url('../../fonts/MinionPro-Regular.eot');
    src: url('../../fonts/MinionPro-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/MinionPro-Regular.woff2') format('woff2'), url('../../fonts/MinionPro-Regular.woff') format('woff'), url('../../fonts/MinionPro-Regular.ttf') format('truetype'), url('../../fonts/MinionPro-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Minion Pro';
    src: url('../../fonts/MinionPro-Bold.eot');
    src: url('../../fonts/MinionPro-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/MinionPro-Bold.woff2') format('woff2'), url('../../fonts/MinionPro-Bold.woff') format('woff'), url('../../fonts/MinionPro-Bold.ttf') format('truetype'), url('../../fonts/MinionPro-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Medium.eot');
    src: url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../../fonts/Roboto-Medium.woff') format('woff'),
    url('../../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Bold.eot');
    src: url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../../fonts/Roboto-Bold.woff') format('woff'),
    url('../../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Light.eot');
    src: url('../../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Light.woff2') format('woff2'),
    url('../../fonts/Roboto-Light.woff') format('woff'),
    url('../../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Regular.eot');
    src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../../fonts/Roboto-Regular.woff') format('woff'),
    url('../../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@mixin font{
    font-family: 'Roboto';
}
@mixin fontTitle{
    font-family: 'Minion Pro';
}
@mixin fontIcon {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}
@mixin fontSmaller {
    font-size: 1.4rem;
    line-height: 2.2rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.3rem;
    }
}
@mixin fontDefault {
    @include font;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: normal;
    color: $colorPrimary;
}
@mixin fontMedium {
    font-size: 1.6rem;
    line-height: 2.2rem;
}
@mixin fontLarger {
    font-size: 2.0rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 420px) {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
}
@mixin specialFont {
    @include fontTitle;
    font-weight: normal;
    text-align: left;
}
@mixin letterSpacing {
    letter-spacing: 0.09rem;
    @media only screen and (max-width: 480px) {
        letter-spacing: 0.05rem;
    }
}

/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
    font-weight: bold;
}

@mixin fontPriceProductsSpecial {
    font-weight: bold;
    color: $colorSecondary;
}

@mixin fontPriceProductsSale {
    font-weight: bold;
    text-decoration: line-through;
}

@mixin fontPriceProductSale {
    font-weight: bold;
    text-decoration: line-through;
}

body {
    @include fontDefault;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include specialFont;
    text-align: left;
    color: $colorPrimary;
}

h1 {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 300;
    margin: 0 0 2rem 0;
    @media only screen and (max-width: 768px) {
        font-size: 3rem;
        line-height: 1.2;
        margin: 0 0 2rem 0;
    }
}

h2 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    &:first-of-type{
        padding-top: 0;
    }
    @media only screen and (max-width: 768px) {
        font-size: 3rem;
        line-height: 1.2;
        margin: 0 0 2rem 0;
    }
}
h3 {
    font-size: 2rem;
    line-height: 2rem;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}
h4 {
    @include fontLarger;
    @include specialFont;
    padding-bottom: 2.8rem;
    @media only screen and (max-width: 480px) {
       padding-bottom: 0.9rem;
    }

}

a {
    color: $colorPrimary;
    text-decoration: none;
}

b,
strong {
    font-weight: bold;
}

ol,
ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
}
p{
    @include fontDefault;
    margin-bottom: .8rem;
    color: $colorPrimary;
}
small, .small{
    font-size: 1.2rem;
}